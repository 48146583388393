import * as React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
} from "@mui/material";


export default function PersonalInfo(props) {
  let handleChange = props.handleChange;
  let handleChangeUserMeta = props.handleChangeUserMeta;
  let state = props.state;
  let validstate = props.validstate;
  let usermeta = props.usermeta;
  let validumetastate = props.validumetastate;
  let isRequiredParentData = props.isRequiredParentData;
  let provinceList = props.provinceList;
  let amphoeList = props.amphoeList;
  let tambonList = props.tambonList;
  let disabled = props.disabled ? true : false;
  return (
    <Grid item className="card-container">
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <span className="text-semi16 text-black-color">
            ข้อมูลประวัติของผู้สมัคร
          </span>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!validumetastate.namePrefix}>
                <InputLabel>คำนำหน้า</InputLabel>
                <Select
                  value={usermeta.namePrefix}
                  name="namePrefix"
                  onChange={handleChangeUserMeta}
                  label="คำนำหน้า"
                  disabled={disabled}
                >
                  <MenuItem value={"เด็กชาย"}>เด็กชาย</MenuItem>
                  <MenuItem value={"เด็กหญิง"}>เด็กหญิง</MenuItem>
                  <MenuItem value={"นาย"}>นาย</MenuItem>
                  <MenuItem value={"นางสาว/นาง"}>นางสาว/นาง</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="ตำแหน่ง / ยศ (ถ้ามี)"
                name="userrank"
                value={usermeta.userrank}
                onChange={handleChangeUserMeta}
                helperText={
                  "ระบุเป็นอักษรย่อ ภาษาไทยและภาษาอังกฤษ เช่น ร.อ./Capt. "
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validstate.firstname}
                id="firstname"
                label="ชื่อ (ภาษาไทย)"
                name="firstname"
                value={state.firstname}
                onChange={handleChange}
                helperText={!validstate.firstname && "กรุณาใส่ชื่อภาษาไทย"}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validstate.lastname}
                id="lastname"
                label="นามสกุล (ภาษาไทย)"
                name="lastname"
                value={state.lastname}
                onChange={handleChange}
                helperText={!validstate.lastname && "กรุณาใส่นามสกุลภาษาไทย"}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validumetastate.firstnameEN}
                label="First Name"
                name="firstnameEN"
                value={usermeta.firstnameEN}
                onChange={handleChangeUserMeta}
                helperText={"ชื่อภาษาอังกฤษ โดยขึ้นต้นด้วยตัวพิมพ์ใหญ่"}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validumetastate.lastnameEN}
                id="lastname"
                label="Last Name"
                name="lastnameEN"
                value={usermeta.lastnameEN}
                onChange={handleChangeUserMeta}
                helperText={"นามสกุลภาษาอังกฤษ โดยขึ้นต้นด้วยตัวพิมพ์ใหญ่"}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validumetastate.dateOfBirth}
                label="วันเกิด"
                name="dateOfBirth"
                type="date"
                id="datePickerId"
                value={usermeta.dateOfBirth}
                onChange={handleChangeUserMeta}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  !validumetastate.dateOfBirth && "กรุณากรอกวันให้ถูกต้อง"
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validstate.phonenumber}
                id="phonenumber"
                label="Phone Number"
                name="phonenumber"
                value={state.phonenumber}
                onChange={handleChange}
                helperText={"กรอกในรูปแบบ 0xxxxxxxxx"}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validstate.email}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={state.email}
                onChange={handleChange}
                helperText={
                  state.email !== ""
                    ? !validstate.email
                      ? state.email.endsWith("@gmail.com")
                        ? "กรุณาตรวจสอบความถูกต้องของอีเมล"
                        : "อีเมลไม่ถูกต้อง กรุณากรอกอีเมลที่เป็น @gmail.com"
                      : ""
                    : "กรุณากรอกอีเมลที่เป็น @gmail.com"
                }
                disabled={disabled}
              />
            </Grid>
            {isRequiredParentData && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="ชื่อ - นามสกุลผู้ปกครอง"
                  name="parentName"
                  value={usermeta.parentName}
                  onChange={handleChangeUserMeta}
                  error={!validumetastate.parentName}
                  disabled={disabled}
                />
              </Grid>
            )}
            {isRequiredParentData && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="เบอร์โทรศัพท์ผู้ปกครอง"
                  name="parentPhonenumber"
                  value={usermeta.parentPhonenumber}
                  onChange={handleChangeUserMeta}
                  helperText="กรอกในรูปแบบ 0xxxxxxxxx"
                  error={!validumetastate.parentPhonenumber}
                  disabled={disabled}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <span className="text-body16 text-black-color">
            กรุณาระบุอำเภอและจังหวัดท่านพักอาศัยอยู่ในปัจจุบัน
          </span>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={!validumetastate.recidenceZipCode}
                label="รหัสไปรษณีย์"
                name="recidenceZipCode"
                value={usermeta.recidenceZipCode}
                onChange={handleChangeUserMeta}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!validumetastate.recidenceProvince}>
                <InputLabel>จังหวัด</InputLabel>
                <Select
                  value={usermeta.recidenceProvince}
                  name="recidenceProvince"
                  label="จังหวัด"
                  onChange={handleChangeUserMeta}
                  disabled={!/^\d{5}$/.test(usermeta.recidenceZipCode) | disabled}
                >
                  {provinceList.map((v) => {
                    return <MenuItem value={v}>{v}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!validumetastate.recidenceAmphoe}>
                <InputLabel>เขต/อำเภอ</InputLabel>
                <Select
                  value={usermeta.recidenceAmphoe}
                  name="recidenceAmphoe"
                  label="เขต/อำเภอ"
                  onChange={handleChangeUserMeta}
                  disabled={
                    !(/^\d{5}$/.test(usermeta.recidenceZipCode) &&
                    (usermeta.recidenceProvince !== ""))|disabled
                  }
                >
                  {amphoeList.map((v) => {
                    return <MenuItem value={v}>{v}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* recidenceTambon */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!validumetastate.recidenceTambon}>
                <InputLabel>แขวง/ตำบล</InputLabel>
                <Select
                  value={usermeta.recidenceTambon}
                  name="recidenceTambon"
                  label="แขวง/ตำบล"
                  onChange={handleChangeUserMeta}
                  disabled={
                    !(/^\d{5}$/.test(usermeta.recidenceZipCode) &&
                    (usermeta.recidenceProvince !== "") &&
                    (usermeta.recidenceAmphoe !== ""))| disabled
                  }
                >
                  {tambonList.map((v) => {
                    return <MenuItem value={v}>{v}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
