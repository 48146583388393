import * as React from "react";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  Radio,
  Grid,
  Autocomplete,
} from "@mui/material";
import config from "../../config.json";
import schoolList from "../../school_list.json";
import occupationTypeList from "../../OccupationType.json";
import facultyList from "../../FacultyList.json";

export default function EducationalAndJobInfo(props) {
  let handleChangeUserMeta = props.handleChangeUserMeta;
  let usermeta = props.usermeta;
  let setUsermeta = props.setUsermeta;
  let validumetastate = props.validumetastate;
  let setValidumetastate = props.setValidumetastate;
  let studyLevelList = props.studyLevelList;
  let disabled = props.disabled ? true : false;
  return (
    <Grid item className="card-container">
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <span className="text-semi16 text-black-color">
            ข้อมูลระดับการศึกษาและการทำงาน
          </span>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <span
                className="text-body16 text-black-color"
                style={!validumetastate.jobStatus ? { color: "red" } : {}}
              >
                สถานภาพของท่านในปัจจุบัน
              </span>
            </Grid>
            <Grid item>
              <RadioGroup
                name="jobStatus"
                value={usermeta.jobStatus}
                onChange={handleChangeUserMeta}
                row
              >
                <FormControlLabel
                  value="เรียน"
                  control={<Radio />}
                  label="เรียน"
                  disabled={disabled}
                />
                <FormControlLabel
                  value="ทำงาน"
                  control={<Radio />}
                  label="ทำงาน"
                  disabled={disabled}
                />
                <FormControlLabel
                  value="ทำงานและเรียนไปด้วย"
                  control={<Radio />}
                  label="ทำงานและเรียนไปด้วย"
                  disabled={disabled}
                />
                <FormControlLabel
                  value="ว่างงาน"
                  control={<Radio />}
                  label="ว่างงาน"
                  disabled={disabled}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        {(usermeta.jobStatus === "เรียน" ||
          usermeta.jobStatus === "ทำงานและเรียนไปด้วย") && (
          <Grid item>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!validumetastate.maxEducationLevel}
                >
                  <InputLabel>กำลังการศึกษาในระดับ</InputLabel>
                  <Select
                    fullWidth
                    value={usermeta.maxEducationLevel}
                    name="maxEducationLevel"
                    label="กำลังการศึกษาในระดับ"
                    onChange={handleChangeUserMeta}
                    disabled={disabled}
                  >
                    {Object.keys(config.studyLevel).map((v) => {
                      return <MenuItem value={v}>{v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!validumetastate.studyLevel}>
                  <InputLabel>ระดับชั้น</InputLabel>
                  <Select
                    value={usermeta.studyLevel}
                    name="studyLevel"
                    label="ระดับชั้น"
                    onChange={handleChangeUserMeta}
                    disabled={
                      !Object.keys(config.studyLevel).includes(
                        usermeta.maxEducationLevel
                      ) || disabled
                    }
                  >
                    {studyLevelList.map((v) => {
                      return <MenuItem value={v}>{v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!validumetastate.graduatedUniversityType}
                >
                  <InputLabel>ประเภทของสถาบันการศึกษา</InputLabel>
                  <Select
                    value={usermeta.graduatedUniversityType}
                    name="graduatedUniversityType"
                    label="ประเภทของสถาบันการศึกษา"
                    onChange={handleChangeUserMeta}
                    disabled={disabled}
                  >
                    {[
                      "สถาบันของรัฐบาล",
                      "สถาบันของเอกชน",
                      "Home school/เรียนอิสระ",
                      "สถาบันต่างประเทศ",
                    ].map((v) => {
                      return <MenuItem value={v}>{v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={schoolList}
                  style={{
                    marginTop: "0px",
                  }}
                  name="graduatedUniversity"
                  value={usermeta.graduatedUniversity}
                  // onChange={handleChangeUserMeta}
                  onChange={(event, newValue) => {
                    setUsermeta((prevState) => ({
                      ...prevState,
                      graduatedUniversity: newValue,
                    }));
                    if (newValue === null || newValue === "") {
                      setValidumetastate((prevState) => ({
                        ...prevState,
                        graduatedUniversity: false,
                      }));
                    } else {
                      setValidumetastate((prevState) => ({
                        ...prevState,
                        graduatedUniversity: true,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="สถาบันการศึกษา"
                      error={!validumetastate.graduatedUniversity}
                      value={usermeta.graduatedUniversity}
                    />
                  )}
                  sx={{ mt: 2 }}
                  disabled={disabled}
                />
              </Grid>
              {usermeta.graduatedUniversity === "อื่นๆ" && (
                <Grid item xs={12} sm={6}></Grid>
              )}
              {usermeta.graduatedUniversity === "อื่นๆ" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="กรุณาระบุชื่อสถาบันการศึกษา"
                    name="otherGraduatedUniversity"
                    value={usermeta.otherGraduatedUniversity}
                    onChange={handleChangeUserMeta}
                    helperText={
                      !validumetastate.otherGraduatedUniversity
                        ? "กรุณาระบุชื่อสถาบันการศึกษา หากเป็นสถาบันนอกประเทศไทย กรุณาระบุประเทศหลังชื่อสถาบันด้วย เช่น Stanford University, USA"
                        : "หากเป็นสถาบันนอกประเทศไทย กรุณาระบุประเทศหลังชื่อสถาบันด้วย เช่น Stanford University, USA"
                    }
                    error={!validumetastate.otherGraduatedUniversity}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {(usermeta.maxEducationLevel ===
                "มัธยมศึกษาตอนปลายหรือเทียบเท่า" ||
                usermeta.maxEducationLevel === "อนุปริญญาหรือเทียบเท่า") && (
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!validumetastate.studyHighschoolPlan}
                      >
                        <InputLabel>แผนการเรียน</InputLabel>
                        <Select
                          value={usermeta.studyHighschoolPlan}
                          name="studyHighschoolPlan"
                          label="แผนการเรียน"
                          onChange={handleChangeUserMeta}
                          disabled={disabled}
                        >
                          {[
                            "สายวิทย์-คณิต",
                            "สายศิลป์-คำนวณ",
                            "สายศิลป์-ภาษา",
                            "สายคอมพิวเตอร์ธุรกิจ",
                            "สายอิเล็กทรอนิกส์",
                            "สายไฟฟ้ากำลัง",
                            "สายเครื่องกล",
                            "อื่นๆ",
                          ].map((v) => {
                            return <MenuItem value={v}>{v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {usermeta.studyHighschoolPlan === "อื่นๆ" && (
                        <TextField
                          fullWidth
                          label="ระบุแผนการเรียน"
                          name="otherHighschoolPlan"
                          value={usermeta.otherHighschoolPlan}
                          onChange={handleChangeUserMeta}
                          error={!validumetastate.otherHighschoolPlan}
                          disabled={disabled}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(usermeta.maxEducationLevel === "ปริญญาตรี" ||
                usermeta.maxEducationLevel === "ปริญญาโท" ||
                usermeta.maxEducationLevel === "ปริญญาเอก") && (
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!validumetastate.studyFaculty}
                      >
                        <InputLabel>คณะ</InputLabel>
                        <Select
                          value={usermeta.studyFaculty}
                          name="studyFaculty"
                          label="คณะ"
                          onChange={handleChangeUserMeta}
                          disabled={disabled}
                        >
                          {facultyList.map((v) => {
                            return <MenuItem value={v}>{v}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {usermeta.studyFaculty === "อื่นๆ" && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="ระบุคณะหรือหลักสูตร"
                          name="otherFaculty"
                          value={usermeta.otherFaculty}
                          onChange={handleChangeUserMeta}
                          error={!validumetastate.otherFaculty}
                          disabled={disabled}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="ภาควิชา / สาขาวิชา / major"
                        name="studyMajor"
                        value={usermeta.studyMajor}
                        onChange={handleChangeUserMeta}
                        error={!validumetastate.studyMajor}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(usermeta.maxEducationLevel === "ปริญญาโท" ||
                usermeta.maxEducationLevel === "ปริญญาเอก") && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="หัวข้อวิจัย/หัวข้อที่สนใจ"
                    name="researchArea"
                    value={usermeta.researchArea}
                    onChange={handleChangeUserMeta}
                    helperText="หัวข้อวิจัยที่กำลังทำอยู่ แม้ว่าหัวข้อนั้นยังไม่ได้สอบก็ตาม เป็นเพียงข้อมูลเพิ่มเติมเท่านั้น"
                    disabled={disabled}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {(usermeta.jobStatus === "ทำงาน" ||
          usermeta.jobStatus === "ทำงานและเรียนไปด้วย") && (
          <Grid item>
            {usermeta.jobStatus === "ทำงาน" && (
              <Grid container spacing={3} paddingBottom={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!validumetastate.maxEducationLevel}
                  >
                    <InputLabel>{"การศึกษาสูงสุด"}</InputLabel>
                    <Select
                      value={usermeta.maxEducationLevel}
                      name="maxEducationLevel"
                      label="การศึกษาสูงสุด"
                      onChange={handleChangeUserMeta}
                      disabled={disabled}
                    >
                      {Object.keys(config.studyLevel).map((v) => {
                        return <MenuItem value={v}>{v}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!validumetastate.graduatedUniversityType}
                  >
                    <InputLabel>ประเภทของสถาบันการศึกษาที่จบมา</InputLabel>
                    <Select
                      value={usermeta.graduatedUniversityType}
                      name="graduatedUniversityType"
                      label="ประเภทของสถาบันการศึกษา"
                      onChange={handleChangeUserMeta}
                      disabled={disabled}
                    >
                      {[
                        "สถาบันของรัฐบาล",
                        "สถาบันของเอกชน",
                        "Home school/เรียนอิสระ",
                        "สถาบันต่างประเทศ",
                      ].map((v) => {
                        return <MenuItem value={v}>{v}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={schoolList}
                    style={{
                      marginTop: "0px",
                    }}
                    name="graduatedUniversity"
                    value={usermeta.graduatedUniversity}
                    // onChange={handleChangeUserMeta}
                    onChange={(event, newValue) => {
                      setUsermeta((prevState) => ({
                        ...prevState,
                        graduatedUniversity: newValue,
                      }));
                      if (newValue === null || newValue === "") {
                        setValidumetastate((prevState) => ({
                          ...prevState,
                          graduatedUniversity: false,
                        }));
                      } else {
                        setValidumetastate((prevState) => ({
                          ...prevState,
                          graduatedUniversity: true,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="สถาบันที่จบการศึกษา"
                        error={!validumetastate.graduatedUniversity}
                        value={usermeta.graduatedUniversity}
                      />
                    )}
                    sx={{ mt: 2 }}
                    disabled={disabled}
                  />
                </Grid>
                {usermeta.graduatedUniversity === "อื่นๆ" ? (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="กรุณาระบุชื่อสถาบันที่จบการศึกษา"
                      name="otherGraduatedUniversity"
                      value={usermeta.otherGraduatedUniversity}
                      onChange={handleChangeUserMeta}
                      helperText={
                        !validumetastate.otherGraduatedUniversity
                          ? "กรุณาระบุชื่อสถาบันที่จบการศึกษา หากเป็นสถาบันนอกประเทศไทย กรุณาระบุประเทศหลังชื่อสถาบันด้วย เช่น Stanford University, USA"
                          : "หากเป็นสถาบันนอกประเทศไทย กรุณาระบุประเทศหลังชื่อสถาบันด้วย เช่น Stanford University, USA"
                      }
                      error={!validumetastate.otherGraduatedUniversity}
                      disabled={disabled}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}></Grid>
                )}
              </Grid>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="ชื่อหน่วยงาน"
                  name="organizationName"
                  value={usermeta.organizationName}
                  onChange={handleChangeUserMeta}
                  error={!validumetastate.organizationName}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!validumetastate.jobType}>
                  <InputLabel>{"ประเภทอาชีพ"}</InputLabel>
                  <Select
                    value={usermeta.jobType}
                    name="jobType"
                    label="ประเภทอาชีพ"
                    onChange={handleChangeUserMeta}
                    disabled={disabled}
                  >
                    {[
                      "พนักงานบริษัทเอกชน",
                      "พนักงานองค์กรภาครัฐ",
                      "เจ้าของธุรกิจ",
                      "วิทยากร ครู หรืออาจารย์",
                      "อาชีพอิสระ / Freelance / Self-employed",
                      "อื่นๆ",
                    ].map((v) => {
                      return <MenuItem value={v}>{v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {usermeta.jobType === "อื่นๆ" && (
                <Grid item xs={12} sm={6}></Grid>
              )}
              {usermeta.jobType === "อื่นๆ" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="อาชีพ อื่น ๆ"
                    name="otherOccupation"
                    value={usermeta.otherOccupation}
                    onChange={handleChangeUserMeta}
                    helperText={
                      !validumetastate.otherOccupation && "กรุณากรอกอาชีพ"
                    }
                    error={!validumetastate.otherOccupation}
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="ตำแหน่งงาน"
                  name="jobPosition"
                  value={usermeta.jobPosition}
                  onChange={handleChangeUserMeta}
                  error={!validumetastate.jobPosition}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="อายุงาน (ปี)"
                  name="jobAge"
                  value={usermeta.jobAge}
                  onChange={handleChangeUserMeta}
                  error={!validumetastate.jobAge}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!validumetastate.jobType}>
                  <InputLabel>{"ลักษณะงานที่ทำ"}</InputLabel>
                  <Select
                    value={usermeta.occupationType}
                    name="occupationType"
                    label="ลักษณะงานที่ทำ"
                    onChange={handleChangeUserMeta}
                    disabled={disabled}
                  >
                    {occupationTypeList.map((v) => {
                      return <MenuItem value={v}>{v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {usermeta.occupationType ===
                "อื่นๆ ที่ไม่สามารถจับเข้ากลุ่มได้ เช่น Contractor (ผู้รับเหมา),Glod DL,กู้ชีพกู้ภัย" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="ลักษณะงานที่ทำ อื่น ๆ"
                    name="otherOccupationType"
                    value={usermeta.otherOccupationType}
                    onChange={handleChangeUserMeta}
                    helperText={
                      !validumetastate.otherOccupationType &&
                      "กรุณากรอกลักษณะงานที่ทำ"
                    }
                    error={!validumetastate.otherOccupationType}
                    disabled={disabled}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {usermeta.jobStatus === "ว่างงาน" && (
          <Grid item>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!validumetastate.maxEducationLevel}
                >
                  <InputLabel>{"การศึกษาสูงสุด"}</InputLabel>
                  <Select
                    value={usermeta.maxEducationLevel}
                    name="maxEducationLevel"
                    label="การศึกษาสูงสุด"
                    onChange={handleChangeUserMeta}
                    disabled={disabled}
                  >
                    {Object.keys(config.studyLevel).map((v) => {
                      return <MenuItem value={v}>{v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!validumetastate.graduatedUniversityType}
                >
                  <InputLabel>ประเภทของสถาบันการศึกษาที่จบมา</InputLabel>
                  <Select
                    value={usermeta.graduatedUniversityType}
                    name="graduatedUniversityType"
                    label="ประเภทของสถาบันการศึกษา"
                    onChange={handleChangeUserMeta}
                    disabled={disabled}
                  >
                    {[
                      "สถาบันของรัฐบาล",
                      "สถาบันของเอกชน",
                      "Home school/เรียนอิสระ",
                      "สถาบันต่างประเทศ",
                    ].map((v) => {
                      return <MenuItem value={v}>{v}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={schoolList}
                  style={{
                    marginTop: "0px",
                  }}
                  name="graduatedUniversity"
                  value={usermeta.graduatedUniversity}
                  // onChange={handleChangeUserMeta}
                  onChange={(event, newValue) => {
                    setUsermeta((prevState) => ({
                      ...prevState,
                      graduatedUniversity: newValue,
                    }));
                    if (newValue === null || newValue === "") {
                      setValidumetastate((prevState) => ({
                        ...prevState,
                        graduatedUniversity: false,
                      }));
                    } else {
                      setValidumetastate((prevState) => ({
                        ...prevState,
                        graduatedUniversity: true,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="สถาบันที่จบการศึกษา"
                      error={!validumetastate.graduatedUniversity}
                      value={usermeta.graduatedUniversity}
                    />
                  )}
                  sx={{ mt: 2 }}
                  disabled={disabled}
                />
              </Grid>
              {usermeta.graduatedUniversity === "อื่นๆ" ? (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="กรุณาระบุชื่อสถาบันที่จบการศึกษา"
                    name="otherGraduatedUniversity"
                    value={usermeta.otherGraduatedUniversity}
                    onChange={handleChangeUserMeta}
                    helperText={
                      !validumetastate.otherGraduatedUniversity
                        ? "กรุณาระบุชื่อสถาบันที่จบการศึกษา หากเป็นสถาบันนอกประเทศไทย กรุณาระบุประเทศหลังชื่อสถาบันด้วย เช่น Stanford University, USA"
                        : "หากเป็นสถาบันนอกประเทศไทย กรุณาระบุประเทศหลังชื่อสถาบันด้วย เช่น Stanford University, USA"
                    }
                    error={!validumetastate.otherGraduatedUniversity}
                    disabled={disabled}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}></Grid>
              )}
            </Grid>
            <Grid item paddingTop="8px">
              <span
                className="text-body16 text-black-color"
                style={!validumetastate.haveWorkExperience ? { color: "red" } : {}}
              >
                มีประสบการณ์ทำงานหรือไม่
              </span>
            </Grid>
            <Grid item>
              <RadioGroup
                name="haveWorkExperience"
                value={usermeta.haveWorkExperience}
                onChange={handleChangeUserMeta}
                row
              >
                <FormControlLabel
                  value="มี"
                  control={<Radio />}
                  label="มี"
                  disabled={disabled}
                />
                <FormControlLabel
                  value="ไม่มี"
                  control={<Radio />}
                  label="ไม่มี"
                  disabled={disabled}
                />
              </RadioGroup>
            </Grid>
            {usermeta.haveWorkExperience === "มี" && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="ชื่อหน่วยงานล่าสุด"
                    name="organizationName"
                    value={usermeta.organizationName}
                    onChange={handleChangeUserMeta}
                    error={!validumetastate.organizationName}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!validumetastate.jobType}>
                    <InputLabel>{"ประเภทอาชีพล่าสุด"}</InputLabel>
                    <Select
                      value={usermeta.jobType}
                      name="jobType"
                      label="ประเภทอาชีพล่าสุด"
                      onChange={handleChangeUserMeta}
                      disabled={disabled}
                    >
                      {[
                        "พนักงานบริษัทเอกชน",
                        "พนักงานองค์กรภาครัฐ",
                        "เจ้าของธุรกิจ",
                        "วิทยากร ครู หรืออาจารย์",
                        "อาชีพอิสระ / Freelance / Self-employed",
                        "อื่นๆ",
                      ].map((v) => {
                        return <MenuItem value={v}>{v}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {usermeta.jobType === "อื่นๆ" && (
                  <Grid item xs={12} sm={6}></Grid>
                )}
                {usermeta.jobType === "อื่นๆ" && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="อาชีพล่าสุด อื่น ๆ"
                      name="otherOccupation"
                      value={usermeta.otherOccupation}
                      onChange={handleChangeUserMeta}
                      helperText={
                        !validumetastate.otherOccupation &&
                        "กรุณากรอกอาชีพล่าสุด"
                      }
                      error={!validumetastate.otherOccupation}
                      disabled={disabled}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="ตำแหน่งงานล่าสุด"
                    name="jobPosition"
                    value={usermeta.jobPosition}
                    onChange={handleChangeUserMeta}
                    error={!validumetastate.jobPosition}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="อายุงาน (ปี)"
                    name="jobAge"
                    value={usermeta.jobAge}
                    onChange={handleChangeUserMeta}
                    error={!validumetastate.jobAge}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!validumetastate.jobType}>
                    <InputLabel>{"ลักษณะงานล่าสุดที่เคยทำ"}</InputLabel>
                    <Select
                      value={usermeta.occupationType}
                      name="occupationType"
                      label="ลักษณะงานล่าสุดที่เคยทำ"
                      onChange={handleChangeUserMeta}
                      disabled={disabled}
                    >
                      {occupationTypeList.map((v) => {
                        return <MenuItem value={v}>{v}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {usermeta.occupationType ===
                  "อื่นๆ ที่ไม่สามารถจับเข้ากลุ่มได้ เช่น Contractor (ผู้รับเหมา),Glod DL,กู้ชีพกู้ภัย" && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="ลักษณะงานล่าสุดที่เคยทำ อื่น ๆ"
                      name="otherOccupationType"
                      value={usermeta.otherOccupationType}
                      onChange={handleChangeUserMeta}
                      helperText={
                        !validumetastate.otherOccupationType &&
                        "กรุณากรอกลักษณะงานล่าสุดที่เคยทำ"
                      }
                      error={!validumetastate.otherOccupationType}
                      disabled={disabled}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
