// import "./App.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
// import PropTypes from 'prop-types';
// import clsx from 'clsx';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  RadioGroup,
  Radio,
  Checkbox,
  Grid,
  Typography,
  Modal as BaseModal,
  // Modal,
} from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import config from "./../config.json";
import provinceData from "./../ProvinceList.json";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";

import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { PersonalInfo, EducationalAndJobInfo } from "./minicomponents";

const theme = createTheme({
  palette: {
    primary: {
      main: "#031d78",
    },
  },
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export default function Register(props) {
  let is_register_open = props.is_register_open ? true : false;
  let [state, setState] = React.useState({
    tid: makeid(20),
    firstname: "",
    lastname: "",
    email: "",
    emailAgain: "",
    phonenumber: "",
    time: new Date(Date.now()).toISOString(),
  });
  let [usermeta, setUsermeta] = React.useState({
    namePrefix: "", //null,
    userrank: "",
    firstnameEN: "",
    lastnameEN: "",
    dateOfBirth: "", //null,
    recidenceTambon: "",
    recidenceAmphoe: "",
    recidenceProvince: "", //null,
    recidenceZipCode: "", //null,
    jobStatus: "",
    // for Children
    parentName: "",
    parentPhonenumber: "",
    // for highschool
    studyHighschoolPlan: "", //null,
    otherHighschoolPlan: "",
    // for normal highschool
    studyLevel: "", //null,
    // for University
    studyFaculty: "", //null,
    otherFaculty: "",
    studyMajor: "", //null,
    // For grated level
    researchArea: "",
    // for working or unemployed
    maxEducationLevel: "", //null,
    graduatedUniversity: "",
    graduatedUniversityType: "",
    otherGraduatedUniversity: "",
    // for working
    jobType: "", //null,
    otherOccupation: "",
    organizationName: "",
    jobPosition: "",
    occupationType: "",
    otherOccupationType: "",
    jobAge: "",
    // for unemployed
    haveWorkExperience: "",
    // Computer Language
    bestProgrammingLanguage: "", //null,
    otherBestProgrammingLanguage: "",
    bestProgrammingLevel: "", //null,
    expertTopic: "",
    AIExpertTopics: [],
    InterestedAITopics: [],
    NextTarget: [],
    Target: "", // null,
    interestedLV2: "", // "ไม่มี", // "",
    acceptRecording: "",
    acceptToUseDataToResearch: "",
    source: [],
    otherSource: "",
    acceptNDA: false,
  });
  let [validstate, setValidstate] = React.useState({
    email: true,
    firstname: true,
    lastname: true,
    phonenumber: true,
  });
  let [validumetastate, setValidumetastate] = React.useState({
    namePrefix: true,
    firstnameEN: true,
    lastnameEN: true,
    dateOfBirth: true,
    recidenceTambon: true,
    recidenceAmphoe: true,
    recidenceProvince: true,
    recidenceZipCode: true,
    jobStatus: true,
    parentName: true,
    parentPhonenumber: true,
    studyHighschoolPlan: true,
    otherHighschoolPlan: true,
    studyLevel: true,
    studyFaculty: true,
    otherFaculty: true,
    studyMajor: true,
    maxEducationLevel: true,
    graduatedUniversity: true,
    graduatedUniversityType: true,
    otherGraduatedUniversity: true,
    jobType: true,
    otherOccupation: true,
    organizationName: true,
    jobPosition: true,
    occupationType: true,
    otherOccupationType: true,
    jobAge: true,
    haveWorkExperience: true,
    bestProgrammingLanguage: true,
    otherBestProgrammingLanguage: true,
    bestProgrammingLevel: true,
    Target: true,
    interestedLV2: true,
    acceptRecording: true,
    acceptToUseDataToResearch: true,
    otherSource: true,
  });
  const [ageWarning, setAgeWarning] = React.useState(false);
  const [dataVerificationModal, setDataVerificationModal] =
    React.useState(false);
  const [iswaiting, setIswaiting] = React.useState(false);
  const [isregistersuccess, setIsregistersuccess] = React.useState(false);
  const [isRequiredParentData, setIsRequiredParentData] = React.useState(false);
  const [provinceList, setProvinceList] = React.useState([]);
  const [amphoeList, setAmphoeList] = React.useState([]);
  const [tambonList, setTambonList] = React.useState([]);
  const [studyLevelList, setStudyLevelList] = React.useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "email") {
      if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value.replace(" ", "")
        ) ||
        !value.trim().endsWith("@gmail.com") ||
        /^[ก-๙]$/.test(value.charAt(0))
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "phonenumber") {
      // value = value.replace(" ", "");
      if (value === "" || !/^0[0-9]{9}$/.test(value.replace(" ", ""))) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "firstname" || name === "lastname") {
      // value = value.replace(" ", "");
      if (
        !/^[ก-๙\-\s.]{2,}$/.test(
          value.trim().replace(" ", "").replace(".", "").replace("-", "")
        ) & /[a-zA-Z]/.test(value)
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else {
      if (value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };
  const handleChangeUserMeta = (e) => {
    const { name, value } = e.target;
    setUsermeta((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "parentPhonenumber") {
      if (value === "" || !/^0[0-9]{9}$/.test(value.replace(" ", ""))) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "firstnameEN" || name === "lastnameEN") {
      // value = value.replace(" ", "");
      if (
        !/^[A-Z][a-z\-\s.]{1,}$/.test(
          value.trim().replace(" ", "").replace(".", "").replace("-", "")
        ) &
        !/^[a-zA-Z]+$/.test(
          value.trim().replace(" ", "").replace(".", "").replace("-", "")
        )
      ) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "dateOfBirth") {
      let is_valid_date = !(
        value.toString() > new Date(Date.now()).toISOString().slice(0, 10)
      );
      if (
        value === "" ||
        !is_valid_date
        // value.toString() > "2017-01-01" ||
        // value.toString() < "1934-01-01"
      ) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
      let age = calculateAge(value);
      if (age < 18) {
        setIsRequiredParentData(true);
      } else {
        setIsRequiredParentData(false);
      }
      // if ((age < 8 || age > 80) && is_valid_date) {
      //   setAgeWarning(true);
      // }
    } else if (name === "recidenceZipCode") {
      setUsermeta((prevState) => ({
        ...prevState,
        recidenceTambon: "",
        recidenceAmphoe: "",
        recidenceProvince: "",
      }));
      if (!/^\d{5}$/.test(value)) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        let province = [
          ...new Set(
            provinceData
              .filter((e) => {
                return e.PostCode === parseInt(value);
              })
              .map((e) => e.ProvinceThai)
          ),
        ];
        setProvinceList(province);
      }
    } else if (name === "recidenceProvince") {
      setUsermeta((prevState) => ({
        ...prevState,
        recidenceTambon: "",
        recidenceAmphoe: "",
      }));
      if (!provinceList.includes(value)) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        let amphoe = [
          ...new Set(
            provinceData
              .filter((e) => {
                return (
                  (e.PostCode === parseInt(usermeta.recidenceZipCode)) &
                  (e.ProvinceThai === value)
                );
              })
              .map((e) => e.DistrictThaiShort)
          ),
        ];
        setAmphoeList(amphoe);
      }
    } else if (name === "recidenceAmphoe") {
      setUsermeta((prevState) => ({
        ...prevState,
        recidenceTambon: "",
      }));
      if (!amphoeList.includes(value)) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        let tambon = [
          ...new Set(
            provinceData
              .filter((e) => {
                return (
                  (e.PostCode === parseInt(usermeta.recidenceZipCode)) &
                  (e.ProvinceThai === usermeta.recidenceProvince) &
                  (e.DistrictThaiShort === value)
                );
              })
              .map((e) => e.TambonThaiShort)
          ),
        ];
        setTambonList(tambon);
      }
    } else if (name === "recidenceTambon") {
      if (!tambonList.includes(value)) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "maxEducationLevel") {
      setUsermeta((prevState) => ({
        ...prevState,
        studyLevel: "",
      }));
      if (config.studyLevel[value] !== null) {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      } else {
        setValidumetastate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      }
      setStudyLevelList(config.studyLevel[value]);
    } else {
      setValidumetastate((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    }
  };

  const handleChangeUserMetaCheckBox = (e) => {
    // const { name, checked } = e.target;
    let name = e.target.name.split("--");
    let checked = e.target.checked;
    if (checked) {
      let new_arr = usermeta[name[0]];
      new_arr.push(name[1]);
      setUsermeta((prevState) => ({
        ...prevState,
        [name[0]]: new_arr,
      }));
    } else {
      setUsermeta((prevState) => ({
        ...prevState,
        [name[0]]: usermeta[name[0]].filter(function (ee) {
          return ee !== name[1];
        }),
      }));
    }
  };
  function check_cond_before_submit() {
    let cond_state = true;
    let local_valid_state = { ...validstate };
    let local_umeta_valid_state = { ...validumetastate };
    let local_umeta_state = { ...usermeta };
    // ["email","firstname","lastname","phonenumber"]
    if (validstate.email && state.email.length !== 0) {
      local_valid_state.email = true;
    } else {
      local_valid_state.email = false;
      cond_state = false;
    }
    if (validstate.firstname && state.firstname.length !== 0) {
      local_valid_state.firstname = true;
    } else {
      local_valid_state.firstname = false;
      cond_state = false;
    }
    if (validstate.lastname && state.lastname.length !== 0) {
      local_valid_state.lastname = true;
    } else {
      local_valid_state.lastname = false;
      cond_state = false;
    }
    if (validstate.phonenumber && state.phonenumber.length !== 0) {
      local_valid_state.phonenumber = true;
    } else {
      local_valid_state.phonenumber = false;
      cond_state = false;
    }
    let arrName = [
      "namePrefix",
      "firstnameEN",
      "lastnameEN",
      "dateOfBirth",
      "recidenceAmphoe",
      "recidenceTambon",
      "recidenceZipCode",
      "recidenceProvince",
      "maxEducationLevel",
      "graduatedUniversity",
      usermeta.graduatedUniversity === "อื่นๆ"
        ? "otherGraduatedUniversity"
        : null,
      "graduatedUniversityType",
      "jobStatus",
      "bestProgrammingLanguage",
      "interestedLV2",
      "Target",
    ];
    for (const idx in arrName) {
      let name = arrName[idx];
      if (name === null) {
        continue;
      }
      if (validumetastate[name] && usermeta[name] !== "") {
        local_umeta_valid_state[name] = true;
      } else {
        local_umeta_valid_state[name] = false;
        cond_state = false;
      }
    }
    if (usermeta.bestProgrammingLanguage === "") {
      if (
        validumetastate.bestProgrammingLevel &&
        usermeta.bestProgrammingLevel !== ""
      ) {
        local_umeta_valid_state.bestProgrammingLevel = true;
      } else {
        local_umeta_valid_state.bestProgrammingLevel = false;
        cond_state = false;
      }
    } else if (usermeta.bestProgrammingLanguage === "ไม่มี") {
      local_umeta_state.bestProgrammingLevel = "";
    } else if (usermeta.bestProgrammingLanguage === "อื่นๆ") {
      if (
        validumetastate.otherBestProgrammingLanguage &&
        usermeta.otherBestProgrammingLanguage !== ""
      ) {
        local_umeta_valid_state.otherBestProgrammingLanguage = true;
      } else {
        local_umeta_valid_state.otherBestProgrammingLanguage = false;
        cond_state = false;
      }
      if (
        validumetastate.bestProgrammingLevel &&
        usermeta.bestProgrammingLevel !== ""
      ) {
        local_umeta_valid_state.bestProgrammingLevel = true;
      } else {
        local_umeta_valid_state.bestProgrammingLevel = false;
        cond_state = false;
      }
    } else {
      local_umeta_state.otherBestProgrammingLanguage = "";
      if (
        validumetastate.bestProgrammingLevel &&
        usermeta.bestProgrammingLevel !== ""
      ) {
        local_umeta_valid_state.bestProgrammingLevel = true;
      } else {
        local_umeta_valid_state.bestProgrammingLevel = false;
        cond_state = false;
      }
    }
    if (isRequiredParentData) {
      let arrName = ["parentName"];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (validumetastate[name] && usermeta[name] !== "") {
          local_umeta_valid_state[name] = true;
        } else {
          local_umeta_valid_state[name] = false;
          cond_state = false;
        }
      }
      if (
        validumetastate.parentPhonenumber &&
        (usermeta.parentPhonenumber !== "" ||
          !/^0[0-9]{9}$/.test(usermeta.parentPhonenumber.replace(" ", "")))
      ) {
        local_umeta_valid_state.parentPhonenumber = true;
      } else {
        local_umeta_valid_state.parentPhonenumber = false;
        cond_state = false;
      }
    } else {
      local_umeta_valid_state.parentName = true;
      local_umeta_valid_state.parentPhonenumber = true;
      local_umeta_state = {
        ...local_umeta_state,
        parentName: "",
        parentPhonenumber: "",
      };
    }
    if (
      usermeta.jobStatus === "เรียน" ||
      usermeta.jobStatus === "ทำงานและเรียนไปด้วย"
    ) {
      if (usermeta.jobStatus === "เรียน") {
        local_umeta_state = {
          ...local_umeta_state,
          // for highschool
          // studyHighschoolPlan: "", //null,
          // otherHighschoolPlan: "",
          // for University
          // studyFaculty: "", //null,
          // otherFaculty: "",
          // studyMajor: "", //null,
          // For grated level
          // researchArea: "",
          // for working
          jobType: "", //null,
          otherOccupation: "",
          organizationName: "",
          jobPosition: "",
          occupationType: "",
          otherOccupationType: "",
          jobAge: "",
          // for unemployed
          // haveWorkExperience: "",
        };
      }
      let arrName = ["studyLevel"];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (name !== null) {
          if (validumetastate[name] && usermeta[name] !== "") {
            local_umeta_valid_state[name] = true;
          } else {
            local_umeta_valid_state[name] = false;
            cond_state = false;
          }
        }
      }
      if (
        usermeta.maxEducationLevel === "ประถมศึกษา" ||
        usermeta.maxEducationLevel === "มัธยมศึกษาตอนต้น"
      ) {
        local_umeta_state = {
          ...local_umeta_state,
          // for highschool
          studyHighschoolPlan: "", //null,
          otherHighschoolPlan: "",
          // for University
          studyFaculty: "", //null,
          otherFaculty: "",
          studyMajor: "", //null,
          // For grated level
          researchArea: "",
          // for working
          // jobType: "", //null,
          // otherOccupation: "",
          // organizationName: "",
          // jobPosition: "",
          // occupationType: "",
          // otherOccupationType: "",
          // jobAge: "",
          // for unemployed
          haveWorkExperience: "",
        };
      } else if (
        usermeta.maxEducationLevel === "มัธยมศึกษาตอนปลายหรือเทียบเท่า" ||
        usermeta.maxEducationLevel === "อนุปริญญาหรือเทียบเท่า"
      ) {
        let arrName = [
          "studyHighschoolPlan",
          usermeta.studyHighschoolPlan === "อื่นๆ"
            ? "otherHighschoolPlan"
            : null,
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name === null) {
            continue;
          }
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for highschool
          // studyHighschoolPlan: "", //null,
          // otherHighschoolPlan: "",
          // for University
          studyFaculty: "", //null,
          otherFaculty: "",
          studyMajor: "", //null,
          // For grated level
          researchArea: "",
          // for working
          // jobType: "", //null,
          // otherOccupation: "",
          // organizationName: "",
          // jobPosition: "",
          // occupationType: "",
          // otherOccupationType: "",
          // jobAge: "",
          // for unemployed
          haveWorkExperience: "",
        };
        if (usermeta.studyHighschoolPlan !== "อื่นๆ") {
          local_umeta_state = {
            ...local_umeta_state,
            otherHighschoolPlan: "",
          };
        }
      } else if (
        usermeta.maxEducationLevel === "ปริญญาตรี" ||
        usermeta.maxEducationLevel === "ปริญญาโท" ||
        usermeta.maxEducationLevel === "ปริญญาเอก"
      ) {
        let arrName = [
          "studyFaculty",
          "studyMajor",
          usermeta.studyFaculty === "อื่นๆ" ? "otherFaculty" : null,
        ];
        for (const idx in arrName) {
          let name = arrName[idx];
          if (name === null) {
            continue;
          }
          if (name !== null) {
            if (validumetastate[name] && usermeta[name] !== "") {
              local_umeta_valid_state[name] = true;
            } else {
              local_umeta_valid_state[name] = false;
              cond_state = false;
            }
          }
        }
        local_umeta_state = {
          ...local_umeta_state,
          // for highschool
          studyHighschoolPlan: "", //null,
          otherHighschoolPlan: "",
          // for University
          // studyFaculty: "", //null,
          // otherFaculty: "",
          // studyMajor: "", //null,
          // For grated level
          // researchArea: "",
          // for working
          // jobType: "", //null,
          // otherOccupation: "",
          // organizationName: "",
          // jobPosition: "",
          // occupationType: "",
          // otherOccupationType: "",
          // jobAge: "",
          // for unemployed
          haveWorkExperience: "",
        };
        if (usermeta.maxEducationLevel === "ปริญญาตรี") {
          local_umeta_state = {
            ...local_umeta_state,
            researchArea: "",
          };
        }
        if (usermeta.studyFaculty !== "อื่นๆ") {
          local_umeta_state = {
            ...local_umeta_state,
            otherFaculty: "",
          };
        }
      }
    }
    if (
      usermeta.jobStatus === "ทำงาน" ||
      (usermeta.jobStatus === "ว่างงาน" && usermeta.jobStatus === "มี") ||
      usermeta.jobStatus === "ทำงานและเรียนไปด้วย"
    ) {
      let arrName = [
        "jobType",
        "organizationName",
        "jobPosition",
        "occupationType",
        "jobAge",
      ];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (
          validumetastate[name] &&
          usermeta[name] !== "" &&
          usermeta[name] !== null
        ) {
          local_umeta_valid_state[name] = true;
        } else {
          local_umeta_valid_state[name] = false;
          cond_state = false;
        }
      }
      if (
        usermeta.graduatedUniversity === "อื่นๆ" &&
        (usermeta.otherGraduatedUniversity === "") |
          (usermeta.otherGraduatedUniversity === null)
      ) {
        local_umeta_valid_state.otherGraduatedUniversity = false;
        cond_state = false;
      } else if (usermeta.graduatedUniversity !== "อื่นๆ") {
        local_umeta_state.otherGraduatedUniversity = "";
        local_umeta_valid_state.otherGraduatedUniversity = true;
      } else {
        local_umeta_valid_state.otherGraduatedUniversity = true;
      }
      if (
        usermeta.occupationType ===
          "อื่นๆ ที่ไม่ได้อยู่ในกลุ่มข้างต้น" &&
        usermeta.otherOccupationType === ""
      ) {
        local_umeta_valid_state.otherOccupationType = false;
        cond_state = false;
      } else if (
        usermeta.occupationType !==
        "อื่นๆ ที่ไม่ได้อยู่ในกลุ่มข้างต้น"
      ) {
        local_umeta_state.otherOccupationType = "";
        local_umeta_valid_state.otherOccupationType = true;
      } else {
        local_umeta_valid_state.otherOccupationType = true;
      }

      if (usermeta.jobType === "อื่นๆ" && usermeta.otherOccupation === "") {
        local_umeta_valid_state.otherOccupation = false;
        cond_state = false;
      } else if (usermeta.jobType !== "อื่นๆ") {
        local_umeta_state.otherOccupation = "";
        local_umeta_valid_state.otherOccupation = true;
      } else {
        local_umeta_valid_state.otherOccupation = true;
      }
      local_umeta_state = {
        ...local_umeta_state,
        // for highschool
        // studyHighschoolPlan: "", //null,
        // otherHighschoolPlan: "",
        // for University
        // studyFaculty: "", //null,
        // otherFaculty: "",
        // studyMajor: "", //null,
        // For grated level
        // researchArea: "",
        // for working
        // jobType: "", //null,
        // otherOccupation: "",
        // organizationName: "",
        // jobPosition: "",
        // occupationType: "",
        // otherOccupationType: "",
        // jobAge: "",
        // for unemployed
        // haveWorkExperience: "",
      };
      if (!(usermeta.jobStatus === "ว่างงาน" && usermeta.jobStatus === "มี")) {
        local_umeta_state.haveWorkExperience = "";
      }
      if (!usermeta.jobStatus === "ทำงานและเรียนไปด้วย") {
        local_umeta_state = {
          ...local_umeta_state,
          // for highschool
          studyHighschoolPlan: "", //null,
          otherHighschoolPlan: "",
          // for University
          studyFaculty: "", //null,
          otherFaculty: "",
          studyMajor: "", //null,
          // For grated level
          researchArea: "",
          // for working
          // jobType: "", //null,
          // otherOccupation: "",
          // organizationName: "",
          // jobPosition: "",
          // occupationType: "",
          // otherOccupationType: "",
          // jobAge: "",
          // for unemployed
          // haveWorkExperience: "",
        };
      }
    }
    if (usermeta.jobStatus === "ว่างงาน") {
      let arrName = ["haveWorkExperience"];
      for (const idx in arrName) {
        let name = arrName[idx];
        if (
          validumetastate[name] &&
          usermeta[name] !== "" &&
          usermeta[name] !== null
        ) {
          local_umeta_valid_state[name] = true;
        } else {
          local_umeta_valid_state[name] = false;
          cond_state = false;
        }
      }
      local_umeta_state = {
        ...local_umeta_state,
        // for highschool
        studyHighschoolPlan: "", //null,
        otherHighschoolPlan: "",
        // for University
        studyFaculty: "", //null,
        otherFaculty: "",
        studyMajor: "", //null,
        // For grated level
        researchArea: "",
        // for working
        // jobType: "", //null,
        // otherOccupation: "",
        // organizationName: "",
        // jobPosition: "",
        // occupationType: "",
        // otherOccupationType: "",
        // jobAge: "",
        // for unemployed
        // haveWorkExperience: "",
      };
      if (usermeta.jobStatus === "") {
      } else if (usermeta.jobStatus === "มี") {
      } else if (usermeta.jobStatus === "ไม่มี") {
        local_umeta_state = {
          ...local_umeta_state,
          // for highschool
          // studyHighschoolPlan: "", //null,
          // otherHighschoolPlan: "",
          // for University
          // studyFaculty: "", //null,
          // otherFaculty: "",
          // studyMajor: "", //null,
          // For grated level
          // researchArea: "",
          // for working
          jobType: "", //null,
          otherOccupation: "",
          organizationName: "",
          jobPosition: "",
          occupationType: "",
          otherOccupationType: "",
          jobAge: "",
          // for unemployed
          // haveWorkExperience: "",
        };
      }
    }
    if (usermeta.source.includes("อื่นๆ")) {
      if (validumetastate.otherSource && usermeta.otherSource !== "") {
        local_umeta_valid_state.otherSource = true;
      } else {
        local_umeta_valid_state.otherSource = false;
        cond_state = false;
      }
    } else {
      local_umeta_state.otherSource = "";
    }
    setUsermeta(local_umeta_state);
    setValidstate(local_valid_state);
    setValidumetastate(local_umeta_valid_state);
    return cond_state;
  }
  async function sentregister() {
    let age = calculateAge(usermeta.dateOfBirth);
    if (validumetastate.dateOfBirth && (age < 8 || age > 80)) {
      setAgeWarning(true);
    } else {
      if (check_cond_before_submit()) {
        setDataVerificationModal(true);
      } else {
        document.getElementById("warning").innerHTML =
          "*กรุณากรอกข้อมูลให้ครบและถูกต้อง";
      }
    }
  }
  function postprocess_phone(value) {
    return (
      value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6, 10)
    );
  }
  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return 0; // Handle case where dateOfBirth is undefined
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    // If the user's birthday hasn't occurred yet this year, subtract 1
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  };

  const SuperAIButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    fontFamily: "Poppins",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={ageWarning}
          onClose={() => {
            setAgeWarning(false);
            setUsermeta((prevState) => ({
              ...prevState,
              dateOfBirth: "",
            }));
            setValidumetastate((prevState) => ({
              ...prevState,
              dateOfBirth: false,
            }));
          }}
        >
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "80vw",
              maxHeight: "80vh",
              borderRadius: "8px",
            }}
          >
            <Grid item className="card-container">
              <Typography id="modal-title" variant="h6" component="h2">
                กรุณายืนยันความถูกต้องของวันเกิด
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                ข้าพเจ้ายืนยันว่าข้าพเจ้าเกิดวันที่{" "}
                {usermeta.dateOfBirth.toString()} เป็นข้อมูลที่ถูกต้อง
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3, marginRight: "8px" }}
                onClick={() => {
                  setAgeWarning(false);
                  if (check_cond_before_submit()) {
                    setDataVerificationModal(true);
                  } else {
                    document.getElementById("warning").innerHTML =
                      "*กรุณากรอกข้อมูลให้ครบและถูกต้อง";
                  }
                }}
              >
                ยืนยัน
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                color="error"
                onClick={() => {
                  setAgeWarning(false);
                  setUsermeta((prevState) => ({
                    ...prevState,
                    dateOfBirth: "",
                  }));
                  setValidumetastate((prevState) => ({
                    ...prevState,
                    dateOfBirth: false,
                  }));
                }}
              >
                Close
              </Button>
            </Grid>
          </Box>
        </Modal>
        <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={dataVerificationModal}
          onClose={() => {
            setDataVerificationModal(false);
          }}
        >
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "80vw",
              maxHeight: "80vh",
              overflowY: "auto",
              overflowX: "hidden",
              borderRadius: "8px",
            }}
          >
            <Grid item className="card-container">
              <Typography id="modal-title" variant="h6" component="h2">
                กรุณายืนยันความถูกต้องของข้อมูล
              </Typography>
              <PersonalInfo
                handleChange={handleChange}
                handleChangeUserMeta={handleChangeUserMeta}
                state={state}
                validstate={validstate}
                usermeta={usermeta}
                validumetastate={validumetastate}
                isRequiredParentData={isRequiredParentData}
                provinceList={provinceList}
                amphoeList={amphoeList}
                tambonList={tambonList}
                disabled={true}
              />
              <EducationalAndJobInfo
                handleChangeUserMeta={handleChangeUserMeta}
                usermeta={usermeta}
                setUsermeta={setUsermeta}
                validumetastate={validumetastate}
                setValidumetastate={setValidumetastate}
                studyLevelList={studyLevelList}
                disabled={true}
              />

              <Button
                variant="contained"
                sx={{ mt: 3, marginRight: "8px" }}
                onClick={async () => {
                  setDataVerificationModal(false);
                  setIswaiting(true);
                  // Fetch data to be
                  let response = await fetch(
                    config.backend_url + "/api/register",
                    {
                      method: "POST",
                      mode: "cors",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Accept: "application/json",
                      },
                      body: JSON.stringify({
                        ...state,
                        firstname: usermeta.firstnameEN,
                        lastname: usermeta.lastnameEN,
                        email: state.email.trim(),
                        phonenumber: postprocess_phone(
                          state.phonenumber.trim()
                        ),
                        usermeta: {
                          email: state.email.trim(),
                          phonenumber: postprocess_phone(
                            state.phonenumber.trim()
                          ),
                          firstnameTH: state.firstname,
                          lastnameTH: state.lastname,
                          ...usermeta,
                        },
                      }),
                    }
                  );
                  if (response.status === 200) {
                    setIsregistersuccess(true);
                  } else {
                    let data = await response.json();
                    // console.log(data);
                    document.getElementById("warning").innerHTML =
                      data["detail"];
                    setIswaiting(false);
                  }
                }}
              >
                ยืนยัน
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                color="error"
                onClick={() => {
                  setDataVerificationModal(false);
                }}
              >
                Close
              </Button>
            </Grid>
          </Box>
        </Modal>
        {is_register_open && (
          <div>
            {!isregistersuccess ? (
              <Box
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={4}
                  noValidate
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <img
                          src="/superai_logo.png"
                          alt=""
                          style={{
                            maxWidth: "120px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <div
                              className="text-semi24 text-black-color"
                              style={{ textAlign: "center" }}
                            >
                              Registration Form Super AI Engineer Season 5
                            </div>
                          </Grid>
                          <Grid item>
                            <div
                              className="text-link16 text-primary-color"
                              style={{ textAlign: "center" }}
                              onClick={() => {
                                window.open("https://superai.aiat.or.th/");
                              }}
                            >
                              ศึกษารายละเอียดโครงการฯ เพิ่มเติม
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <PersonalInfo
                    handleChange={handleChange}
                    handleChangeUserMeta={handleChangeUserMeta}
                    state={state}
                    validstate={validstate}
                    usermeta={usermeta}
                    validumetastate={validumetastate}
                    isRequiredParentData={isRequiredParentData}
                    provinceList={provinceList}
                    amphoeList={amphoeList}
                    tambonList={tambonList}
                  />
                  <EducationalAndJobInfo
                    handleChangeUserMeta={handleChangeUserMeta}
                    usermeta={usermeta}
                    setUsermeta={setUsermeta}
                    validumetastate={validumetastate}
                    setValidumetastate={setValidumetastate}
                    studyLevelList={studyLevelList}
                    // disabled
                  />
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          หัวข้อที่ถนัดทางด้านการเขียนโปรแกรม
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={!validumetastate.bestProgrammingLanguage}
                        >
                          <InputLabel>
                            {"ภาษาทางคอมพิวเตอร์ที่ถนัดที่สุด"}
                          </InputLabel>
                          <Select
                            value={usermeta.bestProgrammingLanguage}
                            name="bestProgrammingLanguage"
                            label="ภาษาทางคอมพิวเตอร์ที่ถนัดที่สุด"
                            onChange={handleChangeUserMeta}
                          >
                            {config.progrmmingList.map((v) => {
                              return <MenuItem value={v}>{v}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      {usermeta.bestProgrammingLanguage === "อื่นๆ" && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={
                              !validumetastate.otherBestProgrammingLanguage
                            }
                            fullWidth
                            label="ภาษาอื่นๆ"
                            name="otherBestProgrammingLanguage"
                            value={usermeta.otherBestProgrammingLanguage}
                            onChange={handleChangeUserMeta}
                          />
                        </Grid>
                      )}
                      {usermeta.bestProgrammingLanguage !== "ไม่มี" && (
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            error={!validumetastate.bestProgrammingLevel}
                          >
                            <InputLabel>{"ระดับความเชี่ยวชาญ"}</InputLabel>
                            <Select
                              value={usermeta.bestProgrammingLevel}
                              name="bestProgrammingLevel"
                              label="ระดับความเชี่ยวชาญ"
                              onChange={handleChangeUserMeta}
                            >
                              {["มาก", "ปานกลาง", "น้อย"].map((v) => {
                                return <MenuItem value={v}>{v}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="ความเชี่ยวชาญอื่น ๆ"
                          name="expertTopic"
                          value={usermeta.expertTopic}
                          onChange={handleChangeUserMeta}
                          helperText="สามารถระบุความเชี่ยวชาญนอกจากเรื่องคอมพิวเตอร์ได้"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          หัวข้อที่ถนัดทางด้านปัญญาประดิษฐ์
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <FormControl>
                              {[
                                "Natural Language Processing",
                                "Image Processing",
                                "Signal Processing",
                                "Data Science",
                                "Robotics / Internet of Thing",
                              ].map((v) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={usermeta.AIExpertTopics.includes(
                                          v
                                        )}
                                        onChange={handleChangeUserMetaCheckBox}
                                        name={"AIExpertTopics--" + v}
                                        color="primary"
                                      />
                                    }
                                    label={v}
                                  />
                                );
                              })}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span
                          className="text-semi16 text-black-color"
                          style={
                            !validumetastate.Target ? { color: "red" } : {}
                          }
                        >
                          ในการเข้าร่วมโครงการนี้
                          ท่านมีเป้าหมายในด้านปัญญาประดิษฐ์ในสาขาใดบ้าง
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <RadioGroup
                              name="Target"
                              value={usermeta.Target}
                              onChange={handleChangeUserMeta}
                            >
                              <FormControlLabel
                                value="AI Innovator (นวัตกร)"
                                control={
                                  <Radio sx={{ alignSelf: "flex-start" }} />
                                }
                                label={
                                  <div>
                                    <Typography variant="body1">
                                      AI Innovator (นวัตกร)
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      เป็นผู้ใช้ AI Tools / API
                                      สร้างสรรค์นวัตกรรม เช่น Prompt Engineer
                                      หรือใช้ AI software ในการทำงาน
                                    </Typography>
                                  </div>
                                }
                                sx={{ alignItems: "flex-start" }}
                              />
                              <FormControlLabel
                                value="AI Engineer (วิศวกร)"
                                control={
                                  <Radio sx={{ alignSelf: "flex-start" }} />
                                }
                                label={
                                  <div>
                                    <Typography variant="body1">
                                      AI Engineer (วิศวกร)
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      เป็นผู้สร้างโมเดล AI
                                      ประยุกต์กับภาคอุตสาหกรรม
                                    </Typography>
                                  </div>
                                }
                                sx={{ alignItems: "flex-start" }}
                              />
                              <FormControlLabel
                                value="AI Researcher (นักวิจัย)"
                                control={
                                  <Radio sx={{ alignSelf: "flex-start" }} />
                                }
                                label={
                                  <div>
                                    <Typography variant="body1">
                                      AI Researcher (นักวิจัย)
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      สร้างสรรค์งานวิจัยและเผยแพร่สู่ระดับสากล
                                    </Typography>
                                  </div>
                                }
                                sx={{ alignItems: "flex-start" }}
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          หลังจบโครงการ ท่านมีแผนจะทำอะไร
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <FormControl>
                              {[
                                "ศึกษาต่อ",
                                "ทำงานสังกัดองค์กร",
                                "ทำงานอิสระ / Freelance (รับงานหรือรับโปรเจคมาทำ)",
                                "เป็นผู้ประกอบการอิสระ / Solopreneur (สร้างเครื่องมือ/ผลิตภัณฑ์/ธุรกิจของตัวเองโดยไม่มีพนักงานประจำ)",
                                "เป็นผู้ประกอบการหรือเจ้าของกิจการ / Entrepreneur (เป็นเจ้าของกิจการที่มีพนักงานประจำ)",
                              ].map((v) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={usermeta.NextTarget.includes(
                                          v
                                        )}
                                        onChange={handleChangeUserMetaCheckBox}
                                        name={"NextTarget--" + v}
                                        color="primary"
                                      />
                                    }
                                    label={v}
                                  />
                                );
                              })}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <span
                              className="text-body16 text-black-color"
                              style={
                                !validumetastate.interestedLV2
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              ท่านมีความตั้งใจสอบคัดเลือกเข้าสู่รอบที่ 2 หรือไม่
                            </span>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              name="interestedLV2"
                              value={usermeta.interestedLV2}
                              onChange={handleChangeUserMeta}
                              error
                            >
                              <FormControlLabel
                                value="มี"
                                control={<Radio />}
                                label="มี"
                              />
                              <FormControlLabel
                                value="ไม่มี"
                                control={<Radio />}
                                label="ไม่มี"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <span className="text-semi16 text-black-color">
                              ท่านทราบข่าวการสมัครจากที่ใด
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl>
                              {[
                                "Website สมาคมปัญญาประดิษฐ์ประเทศไทย",
                                "Facebook เพจ สมาคมปัญญาประดิษฐ์ประเทศไทย",
                                "AIAT MOOC",
                                "Youtube AIAT",
                                "Facebook เพจ Super AI Engineer Development Program",
                                "Youtube Super AI Engineer",
                                "Line Official Account SuperAI Engineer",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคเหนือ",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคกลาง",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคใต้",
                                "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคตะวันออก",
                              ].map((v) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={usermeta.source.includes(v)}
                                        onChange={handleChangeUserMetaCheckBox}
                                        name={"source--" + v}
                                        color="primary"
                                      />
                                    }
                                    label={v}
                                  />
                                );
                              })}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <FormControl>
                                  {[
                                    "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคตะวันตก",
                                    "ข่าวสารศูนย์ประสานงานภูมิภาค ภาคตะวันออกเฉียงเหนือ",
                                    "ผู้เข้าโครงการ Super AI Engineer",
                                    "IT24Hrs - ไอที 24 ชั่วโมง by ปานระพี (ทุกช่องทาง)",
                                    "ครอบครัว / ญาติ",
                                    "ครู / อาจารย์ / โรงเรียน",
                                    "เพื่อน / รุ่นพี่ / รุ่นน้อง",
                                    "Line กลุ่ม",
                                    "ที่ทำงาน",
                                    "เพจ 9arm",
                                    "อื่นๆ",
                                  ].map((v) => {
                                    return (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={usermeta.source.includes(
                                              v
                                            )}
                                            onChange={
                                              handleChangeUserMetaCheckBox
                                            }
                                            name={"source--" + v}
                                            color="primary"
                                          />
                                        }
                                        label={v}
                                      />
                                    );
                                  })}
                                </FormControl>
                              </Grid>
                              {usermeta.source.includes("อื่นๆ") && (
                                <Grid item>
                                  <TextField
                                    fullWidth
                                    label="ท่านทราบข่าวจากอื่น ๆ"
                                    name="otherSource"
                                    value={usermeta.otherSource}
                                    onChange={handleChangeUserMeta}
                                    error={!validumetastate.otherSource}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="card-container">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className="text-semi16 text-black-color">
                          กรุณาระบุข้อความยินยอมการให้ข้อมูลส่วนบุคคล
                          โดยการเก็บข้อมูลส่วนบุคคลครั้งนี้
                          จะถูกจัดเก็บด้วยความระมัดระวัง
                          และอยู่ภายใต้เงื่อนไขและข้อจำกัดต่าง ๆ
                          ที่เกี่ยวกับการดำเนินโครงการนี้เท่านั้น
                          โดยมิได้ใช้เพื่อการค้าหรือเพื่อธุรกิจใด ๆ
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <span className="text-body16 text-black-color">
                              ข้าพเจ้าตกลงยินยอมให้โครงการจัดเก็บข้อมูลส่วนบุคคลที่ข้าพเจ้าได้ให้ไว้ในการลงทะเบียนเข้าร่วมโครงการ
                              และยินยอมให้เปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าต่อหน่วยงานของรัฐ
                              หรือผู้ให้ทุน
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={usermeta.acceptNDA}
                                  onChange={(e) => {
                                    let name = e.target.name;
                                    let checked = e.target.checked;
                                    setUsermeta((prevState) => ({
                                      ...prevState,
                                      [name]: checked,
                                    }));
                                  }}
                                  name={"acceptNDA"}
                                  color="primary"
                                />
                              }
                              label={"ยินยอม"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <span
                                  className="text-body16 text-black-color"
                                  style={
                                    !validumetastate.acceptRecording
                                      ? { color: "red" }
                                      : {}
                                  }
                                >
                                  ในระหว่างการจัดอบรมออนไลน์
                                  ท่านยินยอมที่จะให้ทางโครงการบันทึกข้อมูล
                                  รูปภาพ เสียง
                                  ของท่านซึ่งอาจจะอยู่ในคลิปวีดิโอในระหว่างการอบรมหรือไม่
                                </span>
                              </Grid>
                              <Grid item>
                                <RadioGroup
                                  name="acceptRecording"
                                  value={usermeta.acceptRecording}
                                  onChange={handleChangeUserMeta}
                                >
                                  <FormControlLabel
                                    value="ยินยอม"
                                    control={<Radio />}
                                    label="ยินยอม"
                                  />
                                  <FormControlLabel
                                    value="ไม่ยินยอม"
                                    control={<Radio />}
                                    label="ไม่ยินยอม"
                                  />
                                </RadioGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <span
                                  className="text-body16 text-black-color"
                                  style={
                                    !validumetastate.acceptToUseDataToResearch
                                      ? { color: "red" }
                                      : {}
                                  }
                                >
                                  ในระหว่างการอบรม
                                  จะมีการเก็บข้อมูลพฤติกรรมและผลงานของผู้ร่วมอบรมเพื่อนำไปใช้ในการวิจัยเพื่อการศึกษา
                                  ท่านยินยอมที่จะให้ใช้ข้อมูลพฤติกรรมและผลงานของท่านเพื่อการวิจัยหรือไม่
                                </span>
                              </Grid>
                              <Grid item>
                                <RadioGroup
                                  name="acceptToUseDataToResearch"
                                  value={usermeta.acceptToUseDataToResearch}
                                  onChange={handleChangeUserMeta}
                                >
                                  <FormControlLabel
                                    value="ยินยอม"
                                    control={<Radio />}
                                    label="ยินยอม"
                                  />
                                  <FormControlLabel
                                    value="ไม่ยินยอม"
                                    control={<Radio />}
                                    label="ไม่ยินยอม"
                                  />
                                </RadioGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <SuperAIButton
                              fullWidth
                              variant="contained"
                              onClick={sentregister}
                              disabled={
                                !usermeta.acceptNDA ||
                                usermeta.acceptRecording !== "ยินยอม" ||
                                usermeta.acceptToUseDataToResearch !== "ยินยอม"
                              }
                            >
                              {!iswaiting ? (
                                <div>Register</div>
                              ) : (
                                <div>
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />{" "}
                                  Waiting...
                                </div>
                              )}
                            </SuperAIButton>
                          </Grid>
                          <Grid item xs={12}>
                            <div
                              id="warning"
                              className="text-body16"
                              style={{ color: "red" }}
                            ></div>
                          </Grid>
                          <Grid item xs={12}>
                            {(!usermeta.acceptNDA ||
                              usermeta.acceptRecording !== "ยินยอม" ||
                              usermeta.acceptToUseDataToResearch !==
                                "ยินยอม") && (
                              <p
                                className="text-body16"
                                style={{ color: "red" }}
                              >
                                *กรุณากดยินยอมให้โครงการ จัดเก็บข้อมูลส่วนบุคคล
                                และ บันทึกข้อมูล รูปภาพ เสียง
                                ของท่านซึ่งอาจจะอยู่ในคลิปวีดิโอในระหว่างการอบรมรวมไปถึงการเก็บข้อมูลพฤติกรรมและผลงานของผู้ร่วมอบรมเพื่อนำไปใช้ในการวิจัยเพื่อการศึกษา
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // maxWidth: "480px",
                }}
              >
                <CheckCircleIcon
                  style={{
                    fontSize: "64px",
                    width: "100%",
                    margin: "auto",
                    color: "#49AA19",
                  }}
                />
                <div
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Congratulations!
                </div>
                <div
                  style={{
                    marginBottom: "16px",
                    padding: "16px",
                    textAlign: "center",
                    fontSize: "16px",
                    maxWidth: "540px",
                  }}
                >
                  ขอบคุณที่สมัครเข้าร่วมโครงการ Super AI Engineer (Season 5)
                  การลงทะเบียนเสร็จสิ้นแล้ว ภายใน 24 ชั่วโมงทางโครงการจะส่ง username และ password สำหรับเข้าเรียนระบบ MOOC ให้ทางอีเมลที่ท่านสมัคร
                  ถ้าท่านไม่พบอีเมลจากโครงการ กรุณาตรวจสอบในอีเมลขยะ (Junk mail)
                </div>
              </Box>
            )}
          </div>
        )}
        {!is_register_open && (
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // maxWidth: "480px",
            }}
          >
            {/* <CheckCircleIcon
              style={{
                fontSize: "64px",
                width: "100%",
                margin: "auto",
                color: "#49AA19",
              }}
            /> */}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={4}
              noValidate
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>
                    <img
                      src="/superai_logo.png"
                      alt=""
                      style={{
                        maxWidth: "120px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <div
                          className="text-semi24 text-black-color"
                          style={{ textAlign: "center" }}
                        >
                          Registration Form Super AI Engineer Season 4
                        </div>
                      </Grid>
                      <Grid item>
                        <div
                          className="text-link16 text-primary-color"
                          style={{ textAlign: "center" }}
                          onClick={() => {
                            window.open("https://superai.aiat.or.th/");
                          }}
                        >
                          ศึกษารายละเอียดโครงการฯ เพิ่มเติม
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                className="card-container"
                style={{ paddingTop: "64px", paddingBottom: "64px" }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  spacing={3}
                >
                  <Grid item>
                    <DoNotDisturbOnIcon
                      style={{
                        fontSize: "64px",
                        width: "100%",
                        margin: "auto",
                        color: "#ff0000",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <span className="text-semi24 text-black-color">ขออภัย</span>
                  </Grid>
                  <Grid item style={{ maxWidth: "540px" }}>
                    <span className="text-body16 text-black-color">
                      อยู่ระหว่างปิดระบบลงทะเบียน พบกันใหม่ เร็ว ๆ นี้
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}
